import React from 'react'

import * as styles from './button-white.module.css'

export default function ButtonWhite({ children }) {

  return (
    <button className={styles.button}>
      {children}
    </button>
  )
}
