import React from 'react'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import * as styles from './tickets-btn.module.css'

export default function TicketsBtn(props) {
  const soldOut = props.soldOut
  const ticketSaleLink = props.ticketSaleLink

  return (
    <button className={styles.button} style={{ cursor: soldOut ? 'default' : 'auto'}}>
        {
          soldOut
            ? (
              //<div>
              //  <p className={styles.text}>Tickets</p>
              //  <p className={styles.text}>Sold</p>
              //  <p className={styles.text}>Out!</p>
              //</div>

              <div>
                <p className={styles.text}>Tickets</p>
                <p className={styles.text}>COMING</p>
                <p className={styles.text}>SOON!</p>
              </div>
            )
            :
            (
              <div>
                <a href={ticketSaleLink} target="_blank" rel="noreferrer">
                  <p className={styles.text}>Tickets</p>
                  <p className={styles.text}>available</p>
                  <p className={styles.text}>now! <FontAwesomeIcon icon={faExternalLinkAlt} className="fas fa-external-link"/></p>
                </a>
              </div>
            )
        }
    </button>
  )
}
