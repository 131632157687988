import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faClock, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import * as styles from './date-time-location.module.css'

export default function DateTimeLocation(props) {
  const startTime = new Date(props.startTime)
  const endTime = new Date(props.endTime)
  const location = props.location
  const timeTBAOverride = props.timeTBAOverride

  const timezone = "CET"
  const month = startTime.toLocaleDateString('en-GB', {month: 'long', timezone: timezone});
  const date = startTime.toLocaleDateString('en-GB', {day: 'numeric', timezone: timezone});
  const year = startTime.toLocaleDateString('en-GB', {year: 'numeric', timezone: timezone});
  const startTimeOfDay = startTime.toLocaleTimeString('en-GB', {hour: '2-digit', minute: '2-digit', timezone: timezone});
  const endTimeOfDay = endTime.toLocaleTimeString('en-GB', {hour: '2-digit', minute: '2-digit', timezone: timezone});

  return(
    <div className={styles.tedxki_container}>
      <div className={styles.icon}>
        <FontAwesomeIcon icon={faCalendarAlt} className="fas fa-camera fa-2x mb-2"/>
        {props.startTime ? <h6>{month} {date}<br/>{year}</h6>:<h6>{month} {date}<br/>{year}</h6>}
      </div>
      <div className={styles.icon}>
        <FontAwesomeIcon icon={faClock} className="fas fa-camera fa-2x mb-2" />
        {(props.startTime && !timeTBAOverride) ?(<h6>Time<br/>{startTimeOfDay} - {endTimeOfDay} {timezone}</h6>):(<h6>Time<br/>To be announced</h6>)}
      </div>
      <div className={styles.icon}>
        <FontAwesomeIcon icon={faMapMarkerAlt} className="fas fa-camera fa-2x mb-2" />
        {location ? <h6>{location}<br/>Stockholm</h6>:<h6>Location<br/>To be announced</h6>}
      </div>
    </div>
  )
};
