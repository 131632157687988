// extracted by mini-css-extract-plugin
export var btn_container = "index-module--btn_container--7c43c";
export var coming_up = "index-module--coming_up--6b761";
export var event_title = "index-module--event_title--4b2db";
export var getinvolved = "index-module--getinvolved--bde2d";
export var hero_image = "index-module--hero_image--56ab1";
export var intro = "index-module--intro--37b2c";
export var section = "index-module--section--5f2f7";
export var speaker_photo = "index-module--speaker_photo--2da89";
export var tedxki_container = "index-module--tedxki_container--29e60";
export var ticket_btn_container = "index-module--ticket_btn_container--5d1c2";